$font-stack: -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif;

@import "./fonts/font-awesome/font-awesome";
@import "~bootstrap/scss/bootstrap";
@import "~aos/dist/aos.css";


$color_1: #666;
$color_2: #9c3;
$color_3: #18d36e;
$color_4: #fff;
$color_5: #333;
$color_6: #111;
$color_7: #000;
$color_8: #b8b8b8;
$color_9: #999;
$color_10: red;
$color_11: #eee;
$color_12: #ddd;
$font_family_1: "Open Sans", sans-serif;
$font_family_2: "Montserrat", sans-serif;
$font_family_3: FontAwesome;
$background_color_1: #9c3;
$background_color_2: rgba(0, 0, 0, 0.6);
$background_color_3: transparent;
$background_color_4: #fff;
$background_color_5: lightsteelblue;
$background_color_6: #ddd;

/* Theme Name: BizPage Theme URL: https://bootstrapmade.com/bizpage-bootstrap-business-template/ Author: BootstrapMade.com License: https://bootstrapmade.com/license/ */
/*-------------------------------------------------------------- # General --------------------------------------------------------------*/
/* Back to top button */
/* Prelaoder */
@-webkit-keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Modalloader */
@-webkit-keyframes animate-modalloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes animate-modalloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------------------- # Header --------------------------------------------------------------*/
/*-------------------------------------------------------------- # Intro Section --------------------------------------------------------------*/
/*-------------------------------------------------------------- # Navigation Menu --------------------------------------------------------------*/
/* Nav Menu Essentials */
/* Nav Menu Arrows */
/* Nav Meu Container */
/* Nav Meu Styling */
/* Mobile Nav Toggle */
/* Mobile Nav Styling */
/* Mobile Nav body classes */
/*-------------------------------------------------------------- # Sections --------------------------------------------------------------*/
/* Sections Header --------------------------------*/
/* Section with background --------------------------------*/
/* Featured Services Section --------------------------------*/
/* About Us Section --------------------------------*/
/* Map Section --------------------------------*/
/* Portfolio Section --------------------------------*/
/* Portfolio carousel */
/* Clients Section --------------------------------*/
/* Testimonials Section --------------------------------*/
/* Team Section --------------------------------*/
/* Contact Section --------------------------------*/
/*-------------------------------------------------------------- # Footer --------------------------------------------------------------*/
/*-------------------------------------------------------------- # Responsive Media Queries --------------------------------------------------------------*/
/* HACK */
body {
  background: #fff;
  color: $color_1;
  font-family: $font_family_1;
}
a {
  color: $color_2;
  transition: 0.5s;
  &:hover {
    color: $color_3;
    outline: none;
    text-decoration: none;
  }
  &:active {
    color: $color_3;
    outline: none;
    text-decoration: none;
  }
  &:focus {
    color: $color_3;
    outline: none;
    text-decoration: none;
  }
}
p {
  padding: 0;
  margin: 0;
}
h1 {
  font-family: $font_family_2;
  font-weight: 400;
  margin: 0 0 60px 0;
  padding: 0;
  max-width: 100%;
}
h2 {
  font-family: $font_family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  max-width: 100%;
}
h3 {
  font-family: $font_family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  max-width: 100%;
}
h4 {
  font-family: $font_family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  max-width: 100%;
}
h5 {
  font-family: $font_family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  max-width: 100%;
}
h6 {
  font-family: $font_family_2;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  max-width: 100%;
}
.back-to-top {
  position: fixed;
  background: #9c3;
  color: $color_4;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  border-color: transparent;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 11;
  &:focus{
    outline: none;
  }
  &.hide{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .5s, opacity .5s linear;

  }
  i {
    padding-bottom: 4px;
    color: $color_4;
  }
}
.delete-aos-whitespaces{
  overflow-x: hidden;
  overflow-y: hidden;
}




#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  &:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #f2f2f2;
    border-top: 6px solid #9c3;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }
}

.header {
  padding: 0 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
  a {
    text-decoration: none;
    position: relative;
    margin-right: 10px;
    &:after {
      content: "";
      position: absolute;
      width: 0%;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: $background_color_1;
      transition: width .4s;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
  .logo {
    float: left;
    img {
      height: 25px;
    }
  }
}
.header.header-scrolled {
  background: rgba(0, 0, 0, 0.9);
  transition: all 0.5s;
}
#intro {
  width: 100%;
  height: 100vh;
  background: #0d0d0d;
  .carousel-indicators {
    li {
      background: transparent;
      border: 1px solid #9c3;
    }
    li.active {
      background: #9c3;
    }
    background-color: $background_color_3;
    padding-bottom: 20px;
    margin: 0px auto;
  }
  .carousel-item {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &::before {
      content: '';
      background-color: $background_color_2;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
  .carousel-container {
    opacity: 0.9;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  .carousel-background {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .carousel-content {
    text-align: center;
  }
  h2 {
    color: $color_4;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
  }
  p {
    width: 80%;
    margin: 0 auto 30px auto;
    color: $color_4;
  }
  .carousel-fade {
    overflow: hidden;
    .carousel-inner {
      .carousel-item {
        transition-property: opacity;
      }
    }
  }
  .btn-get-started {
    font-family: $font_family_2;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 32px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    color: $color_4;
    background: #9c3;
    &:hover {
      background: #fff;
      color: $color_2;
    }
  }
}
.nav-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
    margin: 4px 0 0 0;
    padding: 10px;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    background: #fff;
    ul {
      top: 0;
      left: 100%;
      margin: 0;
    }
    li {
      min-width: 180px;
      transition: 0.3s;
      a {
        padding: 10px;
        color: $color_5;
        transition: 0.3s;
        display: block;
        font-size: 13px;
        text-transform: none;
      }
      &:hover {
        >a {
          color: $color_2;
        }
      }
    }
  }
  li {
    position: relative;
    white-space: nowrap;
    &:hover {
      >ul {
        display: block;
      }
      >a {
        color: $color_2;
      }
    }
  }
  >li {
    float: left;
  }
  li.sfHover {
    >ul {
      display: block;
    }
  }
  a {
    padding: 0 10px 10px 0px;
    text-decoration: none;
    display: inline-block;
    color: $color_4;
    font-family: $font_family_2;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    outline: none;
  }
  >.menu-active {
    >a {
      color: $color_2;
    }
  }
}
.sf-arrows {
  .sf-with-ul {
    padding-right: 30px;
    &:after {
      content: "\f107";
      position: absolute;
      right: 15px;
      font-family: $font_family_3;
      font-style: normal;
      font-weight: normal;
    }
  }
  ul {
    .sf-with-ul {
      &:after {
        content: "\f105";
      }
    }
  }
}
#nav-menu-container {
  float: right;
  margin: 0;
}
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
  i {
    color: $color_4;
  }
}
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.8);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      position: relative;
      a {
        color: $color_4;
        font-size: 13px;
        text-transform: uppercase;
        overflow: hidden;
        padding: 10px 22px 10px 15px;
        position: relative;
        text-decoration: none;
        width: 100%;
        display: block;
        outline: none;
        font-weight: 700;
        font-family: $font_family_2;
        &:hover {
          color: $color_2;
        }
      }
      li {
        padding-left: 30px;
      }
    }
    li.menu-active {
      a {
        color: $color_2;
      }
    }
    .menu-has-children {
      i {
        position: absolute;
        right: 0;
        z-index: 99;
        padding: 15px;
        cursor: pointer;
        color: $color_4;
      }
      i.fa-chevron-up {
        color: $color_2;
      }
      li {
        a {
          text-transform: none;
        }
      }
    }
    .menu-item-active {
      color: $color_2;
    }
  }
}
#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}
body.mobile-nav-active {
  overflow: hidden;
  #mobile-nav {
    left: 0;
  }
  #mobile-nav-toggle {
    color: $color_4;
  }
}
.section-header {
  h3 {
    font-size: 32px;
    color: $color_6;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 120px;
      height: 1px;
      background: #ddd;
      bottom: 1px;
      left: calc(50% - 60px);
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 40px;
      height: 3px;
      background: #9c3;
      bottom: 0;
      left: calc(50% - 20px);
    }
  }
  p {
    text-align: center;
    padding-bottom: 30px;
    color: $color_5;
  }
}
.section-bg {
  background: #f7f7f7;
}
#featured-services {
  background: #000;
  .box {
    padding: 30px 20px;
  }
  .box-bg {
    background-image: linear-gradient(0deg, #000 0%, #242323 50%, #000 100%);
  }
  i {
    color: $color_2;
    font-size: 48px;
    display: inline-block;
    line-height: 1;
  }
  h4 {
    font-weight: 400;
    margin: 15px 0;
    font-size: 18px;
    a {
      color: $color_4;
      &:hover {
        color: $color_2;
      }
    }
  }
  p {
    font-size: 14px;
    line-height: 24px;
    color: $color_4;
    margin-bottom: 0;
  }
}
#about {
  background: url("../img/about-bg.jpg") center top no-repeat fixed;
  background-size: cover;
  padding: 60px 0 40px 0;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.92);
    z-index: 9;
  }
  .container {
    position: relative;
    z-index: 10;
  }
  .about-col {
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    &:hover {
      box-shadow: 0px 2px 12px #9c3;
      .icon {
        background-color: $background_color_4;
      }
      i {
        color: $color_2;
      }
    }
    &.bottom-side{
      margin-top: -20px;
    }
    .img {
      position: relative;
      img {
        border-radius: 4px 4px 0 0;
      }
    }
    h2 {
      color: $color_7;
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      padding: 0;
      padding-top: 40px 0 12px 0;
      margin: 0;
      a {
        color: $color_7;
        &:hover {
          color: $color_2;
        }
      }
    }
    p {
      font-size: 14px;
      line-height: 24px;
      color: $color_5;
      margin-bottom: 0;
      padding: 0 20px;
      min-height: 120px;
      text-align: left;
    }
  }
  .icon {
    width: 60px;
    height: 60px;
    padding-top: 7px;
    position: absolute;
    background-color: #9c3;
    border-radius: 50%;
    text-align: center;
    border: 4px solid #fff;
    left: calc(50% - 32px);
    margin-top: -14%;
    transition: .3s;
    z-index: 1;
    i {
      font-size: 32px;
      line-height: 1;
      color: $color_4;
      transition: 0.3s;
    }
  }
}

#portfolio {
  padding-top: 60px;
  #portfolio-filters {
    padding: 0;
    margin: 5px 0 35px 0;
    list-style: none;
    text-align: center;
    li {
      cursor: pointer;
      margin: 15px 15px 15px 0;
      display: inline-block;
      padding: 10px 20px;
      font-size: 12px;
      line-height: 20px;
      color: $color_1;
      border-radius: 4px;
      text-transform: uppercase;
      background: #fff;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: #9c3;
        color: $color_4;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    li.filter-active {
      background: #9c3;
      color: $color_4;
    }
  }
  .portfolio-wrap {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    transition: 0.3s;
    .img-fluid {
      height: 260px;
    }
    &:hover {
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
    }
  }
  .portfolio-item {
    position: relative;
    height: 360px;
    overflow: hidden;
    .modal-dialog.modal-lg {
      max-width: 100%;
      height: auto;
      margin-left: 10px;
    }
    figure {
      background: #000;
      overflow: hidden;
      height: 100%;
      position: relative;
      border-radius: 4px 4px 0 0;
      margin: 0;
      &:hover {
        img {
          opacity: 0.4;
          transition: 0.3s;
        }
        .link-preview {
          opacity: 1;
        }
        .link-details {
          opacity: 1;
          right: calc(50% - 44px);
        }
      }
      .link-preview {
        position: absolute;
        display: inline-block;
        opacity: 0;
        line-height: 1;
        text-align: center;
        width: 36px;
        height: 36px;
        background: #fff;
        border-radius: 50%;
        transition: all 182.828ms linear 0ms;
        right: 44%;
        margin: 0 auto;
        top: calc(50% - 18px);
        i {
          padding-top: 6px;
          font-size: 22px;
          color: $color_5;
        }
        &:hover {
          background: #9c3;
          i {
            color: $color_4;
          }
        }
      }
      .link-details {
        position: absolute;
        display: inline-block;
        opacity: 0;
        line-height: 1;
        text-align: center;
        width: 36px;
        height: 36px;
        background: #fff;
        border-radius: 50%;
        transition: 0.2s linear;
        right: calc(50% - 38px);
        top: calc(50% - 18px);
        i {
          padding-top: 6px;
          font-size: 22px;
          color: $color_5;
        }
        &:hover {
          background: #9c3;
          i {
            color: $color_4;
          }
        }
      }
    }
    .portfolio-info {
      background: #fff;
      text-align: center;
      padding: 15px;
      margin-top: 20px;
      height: 90px;
      border-radius: 0 0 3px 3px;
      h4 {
        font-size: 18px;
        line-height: 1px;
        font-weight: 700;
        margin-bottom: 18px;
        padding-bottom: 0;
        a {
          color: $color_5;
          &:hover {
            color: $color_2;
          }
        }
      }
      p {
        padding: 0;
        margin: 0;
        color: $color_8;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
  .technologies {
    padding: 15px 0;
    .progress {
      height: 35px;
      margin-bottom: 10px;
    }
  }
  .progress {
    .techno {
      font-family: $font_family_1;
      line-height: 35px;
      padding: 0;
      margin: 0 0 0 20px;
      text-transform: uppercase;
      .val {
        float: right;
        font-style: normal;
        margin: 0 20px 0 0;
      }
    }
  }
  .progress-bar {
    width: 1px;
    text-align: left;
    transition: 0.9s;
  }
  .carousel-item {
    height: 500px;
    width: 400px;
    padding: 0px 10px 10px 10px;
    img {
      height: 500px;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .carousel-indicators {
    margin-top: 5px;
    text-align: center;
    position: relative;
    li {
      display: inline-block;
      margin: 0 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $background_color_6;
    }
    li.active {
      background-color: $background_color_1;
    }
  }
  .grey-bg {
    background: #eee;
  }
  .sidebar-fixed {
    position: static;
    top: 130px;
    width: 100%;
  }
}
.modal-dialog.modal-lg {
  .modal-content {
    height: auto;
  }
}
.modal-content {
  .row.fullheight {
    height: 100%;
  }
}
.row.fullheight {
  .project-info {
    padding: 30px;
  }
}
.project-info {
  margin: 0 auto;
  width: 92%;
  .info-title {
    max-width: 300px;
    font-size: 30px;
    margin: 35px auto;
    text-align: center;
  }
  .description {
    text-align: center;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    position: relative;
    margin-bottom: 23px;
  }
  .bar_container {
    position: absolute;
    left: 0;
    height: 5px;
    border-radius: 5px;
    content: "";
    bottom: -8px;
    width: 100%;
    background-color: $background_color_5;
    text-align: right;
  }
  .bar {
    top: 0px;
    left: 0px;
    height: 5px;
    border-radius: 5px;
    content: "";
    position: absolute;
  }
  .pct {
    position: absolute;
    top: -25px;
    right: -32px;
    opacity: 0;
    transition: opacity 0.3s linear;
  }
}
.sl-item {
  img {
    background: #000;
    height: 100%;
    width: 100% !important;
    box-shadow: 3px 3px 20px grey;
    object-fit: cover;
    transition: transform .2s;
  }
}
#clients {
  padding: 60px 0;
  .carousel-item {
    text-align: center;
    img {
      max-width: 100%;
      opacity: 0.5;
      transition: 0.3s;
      padding: 15px 0;
      &:hover {
        opacity: 1;
      }
    }
  }
  .carousel-indicators {
    margin-top: 5px;
    text-align: center;
    position: relative;
    li {
      display: inline-block;
      margin: 0 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $background_color_6;
    }
    li.active {
      background-color: $background_color_1;
    }
  }
}
#testimonials {
  padding: 60px 0;
  .section-header {
    margin-bottom: 40px;
  }
  .carousel-item {
    text-align: center;
    .testimonial-img {
      width: 120px;
      border-radius: 50%;
      border: 4px solid #fff;
      margin: 0 auto;
    }
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 5px 0;
      color: $color_6;
    }
    h4 {
      font-size: 14px;
      color: $color_9;
      margin: 0 0 15px 0;
    }
    .quote-sign-left {
      margin-top: -15px;
      padding-right: 10px;
      display: inline-block;
      width: 37px;
    }
    .quote-sign-right {
      margin-bottom: -15px;
      padding-left: 10px;
      display: inline-block;
      max-width: 100%;
      width: 37px;
    }
    p {
      font-style: italic;
      margin: 0 auto 15px auto;
      max-width: 90%;
    }
  }
  .carousel-indicators {
    margin-top: 5px;
    text-align: center;
    position: relative;
    li {
      display: inline-block;
      margin: 0 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $background_color_6;
    }
    li.active {
      background-color: $background_color_1;
    }
  }
}
#contact {
  padding: 60px 0;
  .contact-info {
    margin-bottom: 20px;
    text-align: center;
    i {
      font-size: 40px;
      display: inline-block;
      margin-bottom: 10px;
      color: $color_2;
    }
    address {
      margin-bottom: 0;
      color: $color_7;
    }
    p {
      margin-bottom: 0;
      color: $color_7;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: bold;
      text-transform: uppercase;
      color: $color_9;
    }
    a {
      color: $color_7;
      &:hover {
        color: $color_2;
      }
    }
  }
  .contact-address {
    margin-bottom: 20px;
  }
  .contact-phone {
    margin-bottom: 20px;
  }
  .contact-email {
    margin-bottom: 20px;
  }
  .form {
    padding-top: 40px;
    #sendmessage {
      color: $color_2;
      border: 1px solid #9c3;
      display: none;
      text-align: center;
      padding: 15px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    #errormessage {
      color: $color_10;
      display: none;
      border: 1px solid red;
      text-align: center;
      padding: 15px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    #sendmessage.show {
      display: block;
    }
    #errormessage.show {
      display: block;
    }
    .show {
      display: block;
    }
    .validation {
      color: $color_10;
      display: none;
      margin: 0 0 20px;
      font-weight: 400;
      font-size: 13px;
    }
    input {
      padding: 25px 14px;
      border-radius: 5px;
      box-shadow: none;
      font-size: 15px;
    }
    textarea {
      padding: 25px 14px;
      border-radius: 5px;
      box-shadow: none;
      font-size: 15px;
    }
    button[type="submit"] {
      background: #9c3;
      border: 0;
      border-radius: 5px;
      padding: 10px 30px;
      color: $color_4;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        background: #13a456;
      }
    }
  }
}
#footer {
  background: #111;
  padding: 0 0 10px 0;
  color: $color_11;
  font-size: 14px;
  .footer-top {
    background: #111;
    padding: 30px 0 0px 0;
    .footer-info {
      margin-bottom: 30px;
      text-align: center;
      h3 {
        font-size: 34px;
        margin: 0 0 20px 0;
        padding: 2px 0 2px 10px;
        line-height: 1;
        font-family: $font_family_2;
        font-weight: 700;
        letter-spacing: 3px;
        border-left: 4px solid #9c3;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-family: $font_family_2;
        color: $color_11;
      }
    }
    .social-links {
      a {
        font-size: 18px;
        display: inline-block;
        background: transparent;
        color: $color_11;
        line-height: 1;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 20%;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
        &:hover {
          background: #9c3;
          color: $color_4;
        }
      }
      div{
        display: inline-block;
      }
    }
    h4 {
      font-size: 14px;
      font-weight: bold;
      color: $color_4;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 12px;
      &::after {
        background: #9c3;
        width: 60px;
      }
    }
    .footer-links {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        i {
          padding-right: 10px;
          color: $color_12;
        }
        li {
          padding: 7px 0;
          &:first-child {
            padding-top: 0px;
          }
        }
        a {
          color: $color_11;
          &:hover {
            color: $color_2;
          }
        }
        a.home {
          &:hover {
            padding-left: 8px;
          }
        }
        a.about {
          &:hover {
            padding-left: 8px;
          }
        }
        a.portfolio {
          &:hover {
            padding-left: 8px;
          }
        }
      }
    }
    .footer-contact {
      margin-bottom: 30px;
      text-align: right;
      p {
        line-height: 32px;
        font-size: 14px;
      }
    }
    .footer-newsletter {
      margin-bottom: 30px;
      input[type="email"] {
        border: 0;
        padding: 6px 8px;
        width: 65%;
      }
      input[type="submit"] {
        background: #9c3;
        border: 0;
        width: 35%;
        padding: 6px 0;
        text-align: center;
        color: $color_4;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          background: #13a456;
        }
      }
    }
  }
  .footer-info {
    .logo {
      width: 180px;
      height: 45px;
      margin-bottom: 20px;
    }
  }
  .footer-links {
    h4 {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        right: 0;
        background: #555;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
      }
    }
  }
  .footer-contact {
    h4 {
      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        height: 2px;
        left: 0;
        background: #555;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        right: 0;
        background: #9c3;
      }
    }
  }
  .copyright {
    position: relative;
    text-align: center;
    padding-top: 30px;
    margin: 0 auto;
    h4 {
      font-size: 13px;
      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 2px;
        left: 0;
        background: #555;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 60px;
        height: 2px;
        right: calc(50% - 30px);
        background: #9c3;
      }
    }

  }
  .credits {
    text-align: center;
    font-size: 13px;
    color: $color_12;
  }
}
@media screen and (max-width: 300px) {
  #portfolio {
    .sl-item {
      height: 150px;
    }
  }
}
@media screen and (min-width: 300px) {
  #portfolio {
    .sl-item {
      height: 310px;
    }
  }
}
@media screen and (min-width: 600px) {
  #portfolio {
    .sl-item {
      height: 250px;
    }
  }
}
@media screen and (min-width: 768px) {
  #portfolio {
    .sl-item {
      height: 330px;
    }
  }
}
@media screen and (min-width: 992px) {
  #portfolio {
    .sl-item {
      height: 375px;
    }
  }
}
@media screen and (min-width: 1400px) {
  #portfolio {
    .sl-item {
      height: 400px;
    }
  }
}
@media screen and (min-width: 1600px) {
  #portfolio {
    .sl-item {
      height: 450px;
    }
  }
}
@media (min-width: 400px) {
  #footer {
    .copyright {
      width: 36%;
    }
  }
}
@media (min-width: 768px) {
  #contact {
    .contact-address {
      padding: 15px 0px 0px;
    }
    .contact-phone {
      padding: 15px 26px 0px 0px;
    }
    .contact-email {
      padding: 15px 26px 0px 0px;
    }
    .left-line{
      border-bottom: 1px solid #ddd;
      position: absolute;
      width: 160px;
      right: 10px;
      bottom: 0;
      transform: rotateY(0deg) rotate(10deg);
    }
    .right-line{
      border-bottom: 1px solid #ddd;
      position: absolute;
      width: 160px;
      left: 10px;
      bottom: 0;
      transform: rotateY(0deg) rotate(170deg);
    }
    .vertical-line{
      border-right: 1px solid #ddd;
      position: absolute;
      height: 140px;
      left: 50%;
      top: 25px;
    }
  }
}
@media (max-width: 768px) {
  .header {
    background-color: $color_6;
    .navbar{
      background-color: $color_6;
    }
    .logo {
      h1 {
        font-size: 28px;
      }
      img {
        max-height: 25px;
      }
    }
  }
  #intro {
    h2 {
      font-size: 28px;
    }
  }
  #nav-menu-container {
    display: none;
  }
  #mobile-nav-toggle {
    display: inline;
  }
  #portfolio {
    .portfolio-wrap {
      .img-fluid {
        height: auto;
      }
    }
  }
  .portfolio-item {
    .modal-dialog.modal-lg {
      height: 100%;
    }
  }
}
@media (min-width: 992px) {
  #testimonials {
    .testimonial-item {
      p {
        width: 80%;
      }
    }
  }
}
@media (max-width: 992px) {
  #footer {
    .footer-top {
      h4 {
        text-align: center;
      }
      .footer-contact {
        p {
          line-height: 32px;
          font-size: 14px;
        }
      }
    }
    .footer-links {
      display: none;
    }
    .footer-contact {
      h4 {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 2px;
          left: 50%;
          margin-left: -30px;
          background: #9c3;
        }
      }
    }
    .copyright {
      width: 80%;
    }
  }
  .footer-top {
    .footer-contact {
      p {
        text-align: center;
      }
    }
  }
}
@media (min-width: 1024px) {

  #intro {
    p {
      width: 60%;
    }
    .carousel-control-prev {
      width: 5%;
    }
    .carousel-control-next {
      width: 5%;
    }
  }
  #nav-menu-container {
    padding-right: 60px;
  }
}

@media (min-width: 1200px) {
  #about{
    .about-col {
      .img {
        position: relative;
        max-height: 205px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  #about{
  .icon {
      margin-top: -11%;
    }
  }
}

@media (max-width: 768px) {
  #about{
  .icon {
      margin-top: -10%;
    }
  }
}

@media (max-width: 550px) {
  #about{
  .icon {
      margin-top: -11%;
    }
  }
}

@media (max-width: 350px) {
  #about{
  .icon {
      margin-top: -16%;
    }
  }
}


.meter {
  height: 5px;  /* Can be anything */
  position: relative;
  background: rgba(85, 85, 85, 0.16);
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
  margin-bottom: 1.5%;
  margin-top: 1.5%;

  & > span {
    display: block;
    height: 100%;
    width: 0;
    -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
    transition: width 3s;
    box-shadow:
            inset 0 2px 9px  rgba(255,255,255,0.3),
            inset 0 -2px 6px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;
  }
}
